import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import NavMenu from './NavMenu/Navmenu';
import StyleTrialImg from './Image/StyleTrialImg';
import WireframeImg from './Image/WireframeImg';
import Footer from './Footer/Footer';
import { footerData } from '../mock/data';
import { PortfolioProvider } from '../context/context';

function StyleTrial() {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [footer, setFooter] = useState({});

  useEffect(() => {
    setFooter({ ...footerData });
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <PortfolioProvider value={{ footer }}>
      <NavMenu />
      <div id="style-trial">
        <Fade right={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
          <div className="header">
            <StyleTrialImg
              imgStyle={{ objectFit: 'contain' }}
              alt="Style Trial Cover Photo"
              filename="style-trial-hero.png"
            />
          </div>
        </Fade>

        <div className="container">
          <div className="overview">
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <h1>Style Trial</h1>

              <div className="details">
                <div>
                  <h3>timeline</h3>
                  <p>3 Months</p>
                </div>

                <div>
                  <h3>team</h3>
                  <p>Lukas Stein</p>
                  <p>Vicky Pei Jou Liu</p>
                  <p>Yani Shi</p>
                </div>

                <div>
                  <h3>tools</h3>
                  <p>Figma</p>
                </div>

                <div>
                  <h3>role</h3>
                  <p>User Research, Design and Prototyping</p>
                </div>
              </div>
            </Fade>

            <Fade right={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <div className="brief">
                <h2>Brief</h2>
                <p>
                  As part of the semester project for the
                  <strong>Design for User Expereince course at USC</strong>, I had the opportunity
                  to work on <strong>StyleTrial</strong> alongside
                  <strong> Vicky Pei Jou Liu, Yani Shi, and Lukas Stein.</strong> We took on the
                  challenge of building StyleTrial, a mobile app that will revolutionize the way we
                  approach online shopping. For this project we employed an iterative process where
                  we actively collect data from our potential users in order to improve our product.
                </p>
              </div>
            </Fade>

            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <div className="brief">
                <h2>User Research Round 1</h2>

                <p>
                  For our initial research session we had the opportunity to interview 5
                  stakeholders in order to validate different problems we identified for the online
                  shopping industry. Data from this round of research indicated that
                  <strong>60% of our stakeholders</strong> expressed an interest in an application
                  that provides users with a virtual dressing room and a customizable avatar. A key
                  takeaway from one of our stakeholders is:
                </p>

                <div
                  style={{
                    fontWeight: 600,
                    textAlign: 'center',
                    fontSize: '24px',
                    maxWidth: '700px',
                    margin: '15px auto',
                  }}
                >
                  <i>
                    &quot;I can&apos;t try on clothes when I&apos;m shopping online and I don&apos;t
                    know how they will fit me.&quot;
                  </i>
                </div>
              </div>
            </Fade>

            <Fade right={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <div className="brief">
                <h2>Defining the Problem</h2>

                <p>
                  After analyzing the data from our first round of research, we constructed the
                  following problem statement:
                  <br />
                  <br />
                </p>
                <div
                  style={{
                    fontWeight: 600,
                    textAlign: 'center',
                    fontSize: '24px',
                    maxWidth: '700px',
                    margin: '15px auto',
                  }}
                >
                  <i>
                    Young adults who want to explore new fashion styles need an interactive way to
                    try on outfits before purchasing them.
                  </i>
                </div>
              </div>
            </Fade>

            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <div className="brief">
                <h2>User Research Round 2</h2>

                <p>
                  Our next goal was to get a better understanding of online shopping habits.
                  Therefore, we conducted further research with 6 different stakeholders by asking
                  them a set of 7 questions. This research allowed us to get an idea of how people
                  think and feel about online shopping. The data reaffirmed that problem we
                  identified is real and that it acts as a deterrent to online shopping.
                  Furthermore, we also utilized this data to start constructing the personas that
                  would engage with the app and the features that address our problem statement.
                </p>

                <div id="embed">
                  <iframe
                    title="reasearch-1"
                    src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTQbS1SpPohwqnd8eNcpJtKli47P29NreZBJkN1Fvm_7TwNcrbRkvlMVwFccvY4tQ7SUB_5HaZm25PF/pubhtml?widget=true&amp;headers=false"
                  />
                  <p style={{ textAlign: 'center', fontWeight: 600, fontSize: '14px' }}>
                    - Research Data -
                  </p>
                </div>
              </div>
            </Fade>

            <Fade right={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <div className="brief">
                <h2>Visual Value Proposition - First Iteration</h2>

                <p style={{ marginBottom: '50px' }}>
                  For our Visual Value Proposition we wanted to make sure that effectively conveyed
                  that StyleTrial would enable users to enter virtual fitting room in order try out
                  any clothes they want. We choose a combination of a catch phrase a picture of full
                  closet. We would soon find out this was not the most effective way of
                  communicating our intent.
                </p>

                <div>
                  <WireframeImg imgStyle={{ objectFit: 'contain' }} alt="VVP" filename="vvp1.png" />
                </div>
              </div>
            </Fade>

            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <div className="brief persona">
                <h2>Primary User</h2>

                <p style={{ marginBottom: '50px' }}>
                  We indentfied <strong>Valarie Chen</strong> as our primary user. Valarie main
                  motivator to engage with StyleTrial is her great sense of fashion and her desire
                  to stay up to date with the latest styles. Valarie utilizes StyleTrial create
                  hundreds of outfit combinations without having to leave the safety of her home.
                  Valarie would serve the main persona would guide the design and functionality of
                  the product.
                </p>

                <WireframeImg
                  imgStyle={{ objectFit: 'contain' }}
                  alt="valarie persona"
                  filename="persona.png"
                />
              </div>
            </Fade>

            <Fade right={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <div className="brief scenario">
                <h2>Scenario</h2>

                <iframe
                  title="scenario"
                  src="https://docs.google.com/document/d/e/2PACX-1vStd86NlgqytPV03Mr-cvS8R5CuNXK3QeU9joKoqP4f0-MJ1adyfyUbtlWweqpjpem_ksvt2eNwXttR/pub?embedded=true"
                />
              </div>
            </Fade>

            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <div className="brief">
                <h2>Storyboard</h2>

                <p style={{ marginBottom: '50px' }}>
                  Our goal was to visually demonstrate what it would like for people to utilize
                  StyleTrial. This helped organize our thoughts as to how the features would be used
                  and what is in order. Although our app logic changed in the later stages of
                  development, the storyboard helped create a good foundation to start from.
                </p>

                <WireframeImg
                  imgStyle={{ objectFit: 'contain' }}
                  alt="storyboard"
                  filename="storyboard.png"
                />
              </div>
            </Fade>

            <Fade right={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <div className="brief">
                <h2>Low Fidelity Wireframes</h2>
                <p>
                  The next step was to start building out the initial wireframes. We tried not to
                  spend too much time on color and the assets. Instead focused on how the user would
                  navigate through the app. Throughout the process we kept referring back to our
                  storyboard and scenario to make sure we were on the right track.
                </p>

                <WireframeImg
                  imgStyle={{ objectFit: 'contain' }}
                  alt="wireframe"
                  filename="wireframe-2.png"
                />
              </div>
            </Fade>

            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <div className="brief embed">
                <h2>User Research 3</h2>
                <p>
                  In this stage of our research, we aimed to measure how well users captured our
                  intent via our wireframes, visual value proposition and scenario. We were able to
                  survey 45 users and we were able to have a focus group of 4 testers. Key takeaways
                  from this round were that the UI for editing the avatar confusing and the flow of
                  the app was not entirely clear. Despite this, around 71% of our respondents
                  understood the value that Style Trial would bring. This stage was really important
                  because it helped us refine our product to better suit the needs of our users.
                </p>

                <div id="embed">
                  <iframe
                    title="reasearch-3"
                    src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTMt_-P3h8u81XC_bS4dnaJVK7GByIfUuFI_7k_N2zlLEU03MCE6ipzWYA6CZ0xB-GjeL3JML5nsNCn/pubhtml?widget=true&amp;headers=false"
                  />
                  <p style={{ textAlign: 'center', fontWeight: 600, fontSize: '14px' }}>
                    - Research Data -
                  </p>
                </div>
              </div>
            </Fade>

            <Fade right={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <div className="brief">
                <h2>Visual value propositon Final Iteration</h2>
                <p>
                  We went for a more refined look. Instead of an image of a boring closet. We opted
                  to create an interactive slider that would cycle different articles of clothing.
                  As a new item is selected and the model is updated to reflect that item. Not does
                  it convey that users will have access to different clothes in the app but also it
                  highlights the ability to try those items on.
                </p>

                <iframe
                  title="vvp"
                  style={{ border: '1px solid rgba(0, 0, 0, 0.1)' }}
                  width="800"
                  height="450"
                  src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FdBjuHLk2Vihnmu9Hf06CV2%2FVisual-Value-Proposition-V1%3Fnode-id%3D183%253A1827%26viewport%3D-118%252C596%252C0.25%26scaling%3Dcontain"
                />
              </div>
            </Fade>

            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <div className="brief">
                <h2>Design System</h2>
                <p>
                  Before we could start on the Minimal Viable Product, we needed to create a Design
                  System. This would ensure that every team member will consistent designs even if
                  they work on different features of the app. Doing this upfront helped us save time
                  because we no longer had to spend deciding on things colors, icons and typography
                  while designing.
                </p>
                <iframe
                  title="prototype"
                  style={{ border: '1px solid rgba(0, 0, 0, 0.1)' }}
                  width="800"
                  height="450"
                  src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FpcmX5xR94DSCREwLCdA1o1%2FDesign-System%3Fnode-id%3D195%253A557%26viewport%3D-1452%252C293%252C0.125%26scaling%3Dcontain"
                />
              </div>
            </Fade>

            <Fade right={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <div className="brief">
                <h2 style={{ marginBottom: '45px' }}>High Fidelity Designs</h2>

                <p style={{ marginBottom: '45px' }}>
                  This is where the team really came together. After three round of user research we
                  were ready to start implementing the insights we have gained into high fidelity
                  designs. In order to make the design process more manageable, we decided to split
                  work based on the features/functionality that Style Trial would have. This way we
                  could all work in parallel and get more done in a small amount of time. After each
                  team member finalized their designs, we came together to start connecting all the
                  frames.
                </p>

                <WireframeImg
                  imgStyle={{ objectFit: 'contain' }}
                  alt="designs"
                  filename="designs-1.png"
                />
              </div>
            </Fade>

            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <div className="brief">
                <WireframeImg
                  imgStyle={{ objectFit: 'contain' }}
                  alt="designs"
                  filename="designs-2.png"
                />
              </div>
            </Fade>

            <Fade right={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <div className="brief">
                <h2>Our Solution</h2>

                <p>
                  After weeks of research and design, we finally have our solution.
                  <strong>
                    Style Trial is mobile app that will revolutionize the way people approach online
                    shopping.
                  </strong>
                  With Style Trial you no longer have to second guess yourself when it comes to
                  deciding where a piece of clothing will fit or not. Instead fire up Style Trial,
                  modify your avatar, and try on outfits to your heart&apos;s content.
                </p>

                <iframe
                  title="prototype"
                  style={{ border: '1px solid rgba(0, 0, 0, 0.1)' }}
                  width="800"
                  height="450"
                  src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FcbrlnEW3kwpI37tAmavB3x%2FFinal-Project-V1%3Fnode-id%3D0%253A1%26viewport%3D489%252C194%252C0.125%26scaling%3Dscale-down"
                />
              </div>
            </Fade>

            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <div className="brief">
                <h2>Pitch Deck</h2>

                <p>
                  Part of the semester project includes doing a 7 minute presentation of our
                  product. In our pitch deck we opted to highlight our primary user and story. With
                  every feature we illustrate what it looks like when a user like Valarie, engages
                  with Style Trial.
                </p>

                <iframe
                  title="pitch-dek"
                  src="https://docs.google.com/presentation/d/e/2PACX-1vSeAjb-j2wzjBtALYadq1aW-slNyzdBOo7pLVRKqu8m7_hXsHX7aukJTdUAugAD710t9LKeLDDiWu-b/embed?start=false&loop=true&delayms=3000"
                  frameBorder="0"
                  width="960"
                  height="569"
                  webkitallowfullscreen="true"
                />
              </div>
            </Fade>

            <Fade right={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <div className="brief">
                <h2>Reflection</h2>
                <p>
                  I definitely learned a lot during this process and it was one of the best
                  experiences I&apos;ve had with a group project. I had great teammates that where
                  very responsive and at the top of their game. At every stage of of the design
                  process, we made sure to utilize each other&apos;s strength. The biggest lesson I
                  learned, was how to manage short deadlines especially when the workload is
                  intense. Communication amongst your team is key to getting done on time and with
                  high quality. Normally i&apos;m more of a quiet person who would give little input
                  and feedback. However, in this project, in this project I challenged myself to be
                  more involved to share my ideas.
                </p>
              </div>
            </Fade>
          </div>
        </div>
      </div>
      <Footer />
    </PortfolioProvider>
  );
}

export default StyleTrial;
