import React from 'react';
import { Helmet } from 'react-helmet';
import { headData } from '../mock/data';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';
import StyleTrial from '../components/StyleTrial';

export default () => {
  const { lang } = headData;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Style Trial | Roberto Sanchez</title>
        <html lang={lang || 'en'} />
        <meta name="description" content="Style Trial" />
      </Helmet>

      <StyleTrial />
    </>
  );
};
